import React from 'react';

/**
 * В данный файл вносятся ассеты из одноименного файла в фигме.
 * https://www.figma.com/file/rJZQqfAYSxZk07CnajqPJo/Assets?node-id=33%3A2
 */

type Fonts = {
  header1: React.CSSProperties;
  header2: React.CSSProperties;
  header3: React.CSSProperties;
  header4: React.CSSProperties;
  header5: React.CSSProperties;
  subheader1: React.CSSProperties;
  subheader2: React.CSSProperties;
  body1: React.CSSProperties;
  body2: React.CSSProperties;
  body3: React.CSSProperties;
  button1: React.CSSProperties;
  button2: React.CSSProperties;
  caption1: React.CSSProperties;
  caption2: React.CSSProperties;
  caption3: React.CSSProperties;
  roboto: React.CSSProperties;
};

const defaultFont: React.CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
};

const fonts: Fonts = {
  header1: {
    ...defaultFont,
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '48px',
  },
  header2: {
    ...defaultFont,
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: '40px',
  },
  header3: {
    ...defaultFont,
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
  },
  header4: {
    ...defaultFont,
    fontSize: '20px',
    lineHeight: '24px',
  },
  header5: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
  },
  subheader1: {
    ...defaultFont,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.005em',
  },
  subheader2: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.01em',
  },
  body1: {
    ...defaultFont,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2: {
    ...defaultFont,
    fontSize: '14px',
    lineHeight: '20px',
  },
  body3: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.01em',
  },
  button1: {
    ...defaultFont,
    fontWeight: 500,
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '20px',
  },
  button2: {
    ...defaultFont,
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '16px',
  },
  caption1: {
    ...defaultFont,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.005em',
  },
  caption2: {
    ...defaultFont,
    fontSize: '12px',
    lineHeight: '16px',
  },
  caption3: {
    ...defaultFont,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  roboto: {
    ...defaultFont,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '16px',
  },
};

export default fonts;
