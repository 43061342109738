import StartupScreen from 'components/system/startupScreen';
import 'config/fonts';
import 'config/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';

const App = React.lazy(() => import('App'));

const container = document.getElementById('root');

if (!container) {
  throw new Error('No root element.');
}

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <>
      <React.Suspense fallback={<StartupScreen />}>
        <App />
      </React.Suspense>
    </>
  </React.StrictMode>
);
