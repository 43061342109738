/* eslint-disable no-restricted-imports */
import { colors, fonts } from 'theme/assets';
import './styles.scss';
import { ReactComponent as LogoIcon } from './loader.svg';

export type StartupScreenProps = {
  text?: string;
};

/**
 * Компонент стартового экрана.
 * Должен быть максимально лёгким и не импортировать в себя тяжелые библиотеки, например, mui.
 */
const StartupScreen: React.FC<StartupScreenProps> = ({ text }) => {
  return (
    <div className="startup-screen">
      {/* Логотип */}
      <div className="logo">
        <LogoIcon />
      </div>

      {/* Описание */}
      {text && (
        <div
          className="text"
          style={{
            ...fonts.body2,
            color: colors.text.secondary,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default StartupScreen;
